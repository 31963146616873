<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="loading" :backdrop="true" />
      <div class="form">
        <div class="form-row">
          <div class="form-col">
            <label for="categories">main topic</label>
            <Dropdown
              trackBy="intent_category_name"
              label="intent_category_name"
              :options="this.optionalData.categories"
              :value="this.optionalData.category"
              @input="setCategoryValue"
              objectMode
            />
            <span v-if="hasError('optionalData.category')" class="form-error">
              {{ showError('optionalData.category') }}
            </span>
          </div>
          <div class="form-col">
            <label for="categories">
              conversation detail
            </label>
            <Dropdown
              trackBy="intent_name"
              label="suggestion_text"
              :options="this.optionalData.intents"
              :value="this.optionalData.intent"
              @input="setIntentValue"
              :disabled="!this.optionalData.category"
              objectMode
            />
            <span v-if="hasError('optionalData.intent')" class="form-error">
              {{ showError('optionalData.intent') }}
            </span>
          </div>
          <div class="form-col">
            <label for="sub_intent">
              next level detail
            </label>
            <Dropdown
              trackBy="intent_name"
              label="suggestion_text"
              :options="this.optionalData.subIntents"
              :value="this.optionalData.subIntent"
              :disabled="!this.optionalData.intent"
              @input="setSubIntentValue"
              objectMode
            />
            <span v-if="hasError('optionalData.subIntent')" class="form-error">
              {{ showError('optionalData.subIntent') }}
            </span>
          </div>
        </div>
      </div>
    <modal-footer :footer="footer" :primary="this.showWarning" />
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import Loader from '@/components/ui/Loader'
import Dropdown from '@/components/ui/Dropdown'
import NotifyMixin from '@/mixins/NotifyMixin'
import ConversationMixin from '@/components/digital_human/ConversationMixin'
import EventBus from '@/utils/EventBus'

export default {
  name: 'DeleteIntent',
  components: { Loader, Dropdown, ModalFooter, },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin, ConversationMixin],
  data() {
    return {
      optionalData: {
        category: null,
        intent: null,
        subIntent: null,
        categories: [],
        intents: [],
        subIntents: [],
      },
      footer: {
        primaryButton: 'delete',
      },
    }
  },
  methods: {
    fetchCategories() {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('categories')
        .then((res) => (this.optionalData.categories = res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchIntents(categoryName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('intents', { category: categoryName })
        .then((res) => (this.optionalData.intents = res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchSubIntents(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
         .getList('subIntentsDetail', { intent: intentName })
        .then((res) => (this.optionalData.subIntents = res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    setSubIntentValue(value) {
      this.optionalData.subIntent = null
      if (!value) return
      this.optionalData.subIntent = value
    },
    setCategoryValue(value) {
      this.optionalData.intents = []
      this.optionalData.subIntent = []
      this.optionalData.category = null
      this.optionalData.intent = null
      this.optionalData.subIntent = null
      if (!value) return
      this.optionalData.category = value
      this.fetchIntents(value?.description)
    },
    setIntentValue(value) {
      this.optionalData.subIntents = []
      this.optionalData.subIntent = null
      this.optionalData.intent = null
      if (!value) return
      this.optionalData.intent = value
      this.fetchSubIntents(value?.intent_name)
    },
    clearForm() {
      this.optionalData.categories = [];
      this.optionalData.category = null;
      this.optionalData.intents = [];
      this.optionalData.intent = null;
      this.optionalData.subIntents = [];
      this.optionalData.subIntent = null;
    },
    onDelete() {
      if (!this.validator.passes()) return
      this.setLoading(true)
      const subIntentName = this.optionalData.subIntent?.intent_name === 'general'
        ? this.optionalData.intent.intent_name
        : this.optionalData.subIntent?.intent_name;
      this.$dhDataProvider
        .delete('deleteIntent', {
           id: subIntentName,
          })
        .then(() => {
          this.clearForm();
          this.fetchCategories();
          this.notifySuccess('The global response was successfully deleted')
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => {
          this.setLoading(false)
        })
    },
    showWarning() {
      EventBus.emit('show-modal-confirmation', {
        id: 'dh-manage-delete-intent',
        title: 'global response catalog',
        showConfirmation: true,
        confirmationMessage: 'Are you sure you want to delete this intent? It will be removed for all languages',
        confirmationType: 'warning',
      })
      EventBus.once('confirm-dh-manage-delete-intent', () => {
        this.onDelete()
      })
    }
  },
  validations: {
    'optionalData.category': 'required',
    'optionalData.intent': 'required',
    'optionalData.subIntent': 'required',
  },
  created() {
    this.initValidator()
    this.fetchCategories()
  },
}
</script>
<style scoped>
.isDisabled {
  @apply text-gray-400 !important;
}
.element {
    @apply w-full font-400 h-10 text-base;
}
.bordered {
  @apply border border-gray-300 rounded-xs outline-none py-2 px-3;
}
.container-element {
  max-height: 10rem;
  overflow: auto;
}
</style>
