import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('conversation')

export default {
  computed: mapState({
    categories: (state) => state.categories,
    intents: (state) => state.intents,
    subIntents: (state) => state.subIntents,
    responses: (state) => state.responses,
    photos: (state) => state.photos,
    category: (state) => state.category,
    intent: (state) => state.intent,
    subIntent: (state) => state.subIntent,
    loading: (state) => state.loading,
  }),
  methods: mapActions([
    'loadCategories',
    'loadIntents',
    'loadSubIntents',
    'loadResponses',
    'loadPhotos',
    'setCategory',
    'setIntent',
    'setSubIntent',
    'setLoading',
    'cleanState',
  ]),
}
